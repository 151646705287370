@use "variables" as v;
@use "home.scss";
@use "enrichment.scss";

html {
  font-size: 12px; // 1rem is 10px
  color: v.$black-text;
}
// *,
// *::after,
// *::before {
//   margin: 0;
//   padding: 0;
// }

.trash-icon {
  font-size: 20px;

  &:hover {
    color: red;
    cursor: pointer;
  }
}

#alertmsg {
  padding: 20px;
}

.button {
  border-radius: 5px;
  box-sizing: border-box;
  font-weight: bold;
  font-size: 14px;
  height: 30px;
  width: 80%;

  &__black-white {
    background: v.$dark-gray !important;
    border: 1px solid white !important;
    color: white !important;
  }

  // &__black-white:hover,
  // &__black-white:active {
  //   background-color: v.$green;
  //   border: 1px solid v.$green;
  //   color: white !important;
  // }

  &__green-white {
    background: v.$green !important;
    border: 1px solid v.$green !important;
    color: white !important;
  }

  &__green-white:hover,
  &__green-white:active {
    background: v.$dark-gray !important;
    border: 1px solid white !important;
    color: white !important;
  }

  &__green-black {
    background: v.$light-green;
    border: 1px solid v.$light-green;
    color: v.$black-text;
  }

  // &__green-black:hover,
  // &__green-black:active {
  //   background: v.$light-green !important;
  //   border: 1px solid v.$light-green !important;
  //   color: white !important;
  // }

  // &__green-white {
  //   background: v.$green;
  //   border: 1px solid v.$green;
  //   color: white;
  // }

  &__lightgreen-black {
    background: #7bd9b7;
    border: 1px solid v.$black-text;
    color: #353434;
  }
  &__darkgreen-black {
    background: #0bafaf;
    color: v.$black-text;
    border: 1px solid white;
  }

  &__darkgreen-black-2 {
    background: #0bafaf;
    margin: 1rem;
    color: white;
    border: 1px solid #0bafaf;
  }

  &__blue-white {
    background: v.$light-blue !important;
    border: 1px solid v.$light-blue;
    color: white;
  }
  &__blue-white:hover,
  &__blue-white:active {
    background: v.$dark-gray !important;
    // border: 1px solid white !important;
    color: white !important;
  }

  &__dark-blue-white {
    background: v.$dark-blue !important;
    border: 1px solid v.$dark-blue !important;
    color: white;
  }
}

.spacing {
  &-1 {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  &-2 {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  &-3 {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  &-4 {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  &-5 {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}

.video-player video {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.input-buttons {
  // background-color: #20be85;
  font-size: 14px;
  font-weight: bold;
  border-radius: 5px;
  border: none;
}
/* Custom sizes react-bootstrap switch */

/* for sm */

.custom-switch.custom-switch-sm .custom-control-label {
  padding-left: 1rem;
  padding-bottom: 1rem;
}

.custom-switch.custom-switch-sm .custom-control-label::before {
  height: 1rem;
  width: calc(1rem + 0.75rem);
  border-radius: 2rem;
}

.custom-switch.custom-switch-sm .custom-control-label::after {
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  border-radius: calc(1rem - (1rem / 2));
}

.custom-switch.custom-switch-sm
  .custom-control-input:checked
  ~ .custom-control-label::after {
  transform: translateX(calc(1rem - 0.25rem));
}

/* for md */

.custom-switch.custom-switch-md .custom-control-label {
  padding-left: 2rem;
  padding-bottom: 1.5rem;
}

.custom-switch.custom-switch-md .custom-control-label::before {
  height: 1.5rem;
  width: calc(2rem + 0.75rem);
  border-radius: 3rem;
}

.custom-switch.custom-switch-md .custom-control-label::after {
  width: calc(1.5rem - 4px);
  height: calc(1.5rem - 4px);
  border-radius: calc(2rem - (1.5rem / 2));
}

.custom-switch.custom-switch-md
  .custom-control-input:checked
  ~ .custom-control-label::after {
  transform: translateX(calc(1.5rem - 0.25rem));
}

/* for lg */

.custom-switch.custom-switch-lg .custom-control-label {
  padding-left: 3rem;
  padding-bottom: 2rem;
}

.custom-switch.custom-switch-lg .custom-control-label::before {
  height: 2rem;
  width: calc(3rem + 0.75rem);
  border-radius: 4rem;
}

.custom-switch.custom-switch-lg .custom-control-label::after {
  width: calc(2rem - 4px);
  height: calc(2rem - 4px);
  border-radius: calc(3rem - (2rem / 2));
}

.custom-switch.custom-switch-lg
  .custom-control-input:checked
  ~ .custom-control-label::after {
  transform: translateX(calc(2rem - 0.25rem));
}

/* for xl */

.custom-switch.custom-switch-xl .custom-control-label {
  padding-left: 4rem;
  padding-bottom: 2.5rem;
}

.custom-switch.custom-switch-xl .custom-control-label::before {
  height: 2.5rem;
  width: calc(4rem + 0.75rem);
  border-radius: 5rem;
}

.custom-switch.custom-switch-xl .custom-control-label::after {
  width: calc(2.5rem - 4px);
  height: calc(2.5rem - 4px);
  border-radius: calc(4rem - (2.5rem / 2));
}

.custom-switch.custom-switch-xl
  .custom-control-input:checked
  ~ .custom-control-label::after {
  transform: translateX(calc(2.5rem - 0.25rem));
}

/* tag input */

// .tag-input.tag-item {
//   background-color: #d4d5d6;
//   display: inline-block;
//   font-size: 14px;
//   border-radius: 30px;
//   height: 30px;
//   padding: 0 4px 0 1rem;
//   display: inline-flex;
//   align-items: center;
//   margin: 0 0.3rem 0.3rem 0;
// }

.tag-input {
  &.tag-items-container{
    background-color: #d4d5d64f;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    padding: 5px;
    max-height: 115px;
    overflow-x: auto;
  }
  // &.tag-item-text{
  //   display: inline;
  //   width: unset;
  //   margin-right: -100%;
  //   padding-right: 100%;
  //   white-space: nowrap;
  // }
  &.tag-item {
    background-color: #fff;
    border: .3px solid #353434;
    display: inline-block;
    font-size: 14px;
    border-radius: 30px;
    height: 30px;
    padding: 0 4px 0 1rem;
    display: inline-flex;
    align-items: center;
    margin: 0 0.3rem 0.3rem 0;

    &-button {
      background-color: #fff;
      color: #353434;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      border: none;
      cursor: pointer;
      font: inherit;
      margin-left: 10px;
      font-weight: bold;
      padding: 0;
      line-height: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

// .tag-input.tag-item-button {
//   background-color: #fff;
//   color: #353434;
//   width: 24px;
//   height: 24px;
//   border-radius: 50%;
//   border: none;
//   cursor: pointer;
//   font: inherit;
//   margin-left: 10px;
//   font-weight: bold;
//   padding: 0;
//   line-height: 1;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// }
