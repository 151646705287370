//ADA standard web colors

// Green > #2E8540
// Light Blue > #247DAE
// Dk Blue > Keep the same
// Highlight > #212121 on #fff1d2
// follow-through text > #212121 on #f9dede
// Black text > #212121
// Blue text > #0071bc

$green: #2e8540;
$light-green: #44d561;
$dark-gray: #353434;
$light-blue: #247dae;
$dark-blue: #1d224b;

$black-text: #212121;
$blue-text: #0071bc;

// media query breakpoints
$small: 995px;
$medium: 995px;
$big: 1200px;
