.filestatus {
  color: white;
  height: 25vh;
  overflow-y: auto;

  &-section {
    &-progress {
      border-radius: 0px;
      height: 25px;

      & .progress-bar {
        background-color: #2991cb;
        font-family: Catamaran, sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 1em;
        line-height: 37px;
      }
    }

    &-label {
      font-family: "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
      font-size: 0.9em;
      font-style: normal;
      font-weight: normal;
      line-height: 19px;
      text-align: left;
      word-break: break-all;
      font-size: 13px;
    }
  }
}
