.sidebar {
  background: #1d224b;
  border-right: 0.5px solid white;
  cursor: pointer;
  font-family: Catamaran;
  font-weight: 600;
  font-size: 13px;
  height: 100vh;
  left: 0;
  position: sticky;
  top: 0;
  width: 46px;

  &-expand {
    width: 202px;
  }

  &-expand-button {
    align-self: center;
    color: white;
    margin-right: 9px;
    text-align: right;
    font-size: 2rem;
  }
}

.side-wrapper {
  height: 100%;
  margin-top: 10%;
}
.sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1rem;
}

.page-content-wrapper {
  width: 100%;
  justify-content: center;
  margin: 0;
}
.sidebar-menu {
  display: list-item;
  border-bottom: 1px solid white;

  &-image {
    height: 20px;
    margin: 8px 0px 8px 8px;
    width: 27px;
    padding: 0;
  }

  &-label {
    color: white;
    margin: 8px 0px 8px 8px;
    padding: 0;
  }
}
