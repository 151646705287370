#home {
  color: white;

  &__tabs_container {
    // border: 1px solid whitesmoke;
    border-radius: 5px;
    // box-shadow: -2px 1px 9px rgba(0, 0, 0, 0.25);
    // background-color: #1D224B; //whitesmoke;
    padding: 0;
  }

  .module-card {
    background-color: #1d224b;
    border-radius: 5px;
    color: white !important;
  }
}

#forgotpassword-form {
  border: 1px solid whitesmoke;
  border-radius: 5px;
  box-shadow: -2px 1px 9px rgba(0, 0, 0, 0.25);
  background-color: whitesmoke;
  padding: 20px;
}

.aime-btn {
  height: 47px;
  width: 192px;
}

.aime-btn-submit {
  background-color: #20be85 !important;
  border-color: #20be85 !important;
}

.or-sep {
  margin-bottom: 3%;
  margin-top: 3%;
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}
@-moz-keyframes rotation {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(359deg);
  }
}
@-o-keyframes rotation {
  from {
    -o-transform: rotate(0deg);
  }
  to {
    -o-transform: rotate(359deg);
  }
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.spinner,
.spinner-small {
  height: 30px;
  width: 30px;
  margin: 0px auto;
  position: absolute;
  top: 50%;
  left: -15%;
  margin: -20px -20px 0 0;
  border: 4px solid #fff;
  border-top: 2px solid #20be85;
  border-radius: 100%;
  -webkit-animation: rotation 0.6s infinite linear;
  -moz-animation: rotation 0.6s infinite linear;
  -o-animation: rotation 0.6s infinite linear;
  animation: rotation 0.6s infinite linear;
}
.prof-img > img {
  height: 30%;
}
.text-right {
  text-align: right;
}

@media (max-width: 100%) {
  .prof-img > img {
    height: 70px;
  }
}
