@use "../../style/variables" as v;
@import "../../style/mixins.scss";
@include threedots-label();

.navbar {
  border-bottom: 1px solid #979797;
  &__with-watson {
    margin: 11px 20px;
  }
  &-icons {
    height: 33px;
    width: 33px;
  }
  // &-user-icon::after {
  //   content: "2";
  // }

  &-divider {
    margin: 0;
    border-color: #2d2d2d;
  }
}
#navuser {
  padding-bottom: 0;
}
#navuser,
#navnotifications {
  text-decoration: none;

  &.nav-link-active {
    color: #007bff;
  }

  &:after {
    display: none !important;
  }
}

.navnotifications {
  .dropdown-menu {
    z-index: 1071;
    max-height: 328px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .dropdown-menu.show {
    @media screen and (max-width: v.$small) {
      margin: 0 auto !important;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 93vw;
    }
    @media screen and (min-width: v.$medium) {
      width: 54vw;
    }
  }

  &-item {
    &-heading {
      background-color: whitesmoke !important;
    }

    &-sticky {
      position: sticky;
      top: 0;
    }
  }
}

.navuser,
.navnotifications {
  .dropdown-menu {
    padding-top: 0px;
    padding-bottom: 0px;
    border-radius: 0px;
    border-color: #2d2d2d;
  }

  &-count {
    height: 21px;
    width: 21px;
    position: absolute;
    top: 2px;
    right: 0px;
    text-align: center;
    padding: 2px 0px 0px 0px;
    background-color: rgb(236, 60, 60);
    color: white;
    font-size: 12px;
    border-radius: 50%;
  }

  &-item {
    padding: 0.85rem 1.25rem;
    min-height: 40px;
    font-style: normal;
    font-size: 1.2em;
    // @extend %threedots-label;
    white-space: normal;

    &-heading {
      cursor: default;
      color: v.$green !important;
      text-align: center;
      font-weight: bold;
      padding-bottom: 0.75rem;
      font-size: 1.3em;
      white-space: nowrap;
    }

    &-plan {
      font-size: 12px;
      padding-top: 0;
      min-height: 22px;
    }

    &-dismiss {
      font-size: 0.8em;
    }
  }
}
.navuser {
  .dropdown-menu {
    min-width: 40ch;
  }
  &-item {
    text-align: center;
  }
}
#logo {
  &:hover {
    cursor: pointer;
  }
}

#nav-toggle {
  background-color: white;
}

.navbarmenu {
  max-width: "100%";
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
