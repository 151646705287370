#content-card {
  height: 200px;
}

.download-icon {
  height: 20px;
  margin-left: 2px;

  &:hover {
    color: #28a745;
  }
}

.aime-info-icon {
  font-size: 24px;
  cursor: pointer;
  &:hover {
    color: #20be85;
  }
}
