@import url("https://fonts.googleapis.com/css2?family=Catamaran:wght@400;500;600;700&family=Roboto&display=swap");

.app-container {
  font-family: "Roboto", sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0;
  font-family: Catamaran;
  font-weight: 700;
}
.transparent-button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.dropdown-menu {
  z-index: 1071;
}
.modal {
  z-index: 1074;
}
.tooltip {
  z-index: 1073;
  ul {
    margin: 0;
    padding: 0;
  }
}

body {
  overflow-x: hidden;
  font-weight: normal;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -webkit-tap-highlight-color: transparent;
  //   background-image: url("./../static/assets/images/backgroundImage/bckgroundimg.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  // background-color: rgb(19, 19, 247);
  background-color: white;

  .n-link {
    color: white !important;
  }

  .n-link > a {
    color: white !important;
    text-decoration: none !important;
  }
}
.heading {
  font-family: Catamaran;
  font-weight: 700;
}
.border-black {
  border: 1px solid black;
}
.color-black {
  color: #000000;
}
.color-white {
  color: #ffffff;
}
.border-black {
  border: #000000 1px solid;
}

.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
}
.align-content {
  display: flex;
  align-items: center;
}

.justify-content {
  display: flex;
  justify-content: center;
}
.center-vertical {
  display: flex;
  // justify-content: center;
  align-items: center;
}
.center-horizontal {
  display: flex;
  justify-content: center;
  // align-items: center;
}
.link {
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
}
.normal-weight {
  font-weight: normal;
}
.three-dots-overflow {
  white-space: nowrap;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
}
.cursor-pointer {
  cursor: pointer;
}
.background-color-white {
  background-color: white;
}
.background-color-black {
  background-color: black;
}
.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
  outline: none;
}

.link-button:hover,
.link-button:focus {
  text-decoration: none;
}
.link-button:hover,
.link-button:active,
.link-button:focus {
  outline: none;
}

.brand-link,
.brand-link:hover {
  color: inherit;
}

.tooltip__content-top {
  color: white;
  font-size: 13px;
  &__a {
    font-weight: 700;
    color: #20be85;
  }
  .tooltip-inner {
    background-color: #2d2d2d;
  }
  .arrow::before {
    border-top-color: #2d2d2d;
  }
}

.tooltip__content-bottom {
  color: white;
  font-size: 13px;
  &__a {
    font-weight: 700;
    color: #20be85;
  }
  .tooltip-inner {
    background-color: #2d2d2d;
  }
  .arrow::before {
    border-bottom-color: #2d2d2d;
  }
}
.list-style-none {
  list-style-type: none;
}
.text-underline {
  text-decoration: underline !important;
}

.custom-checkbox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.custom-checkbox-container input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkbox {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border: 1px solid #2d2d2d;
  cursor: pointer;
}
.checkbox-disabled {
  border: 1px solid #6e6e6e;
}

/* On mouse-over, add a grey background color */
.custom-checkbox-container:hover input ~ .checkbox {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.custom-checkbox-container input:checked ~ .checkbox {
  background-color: #2d2d2d;
}
.custom-checkbox-container input:checked ~ .checkbox-disabled {
  background-color: #6e6e6e;
}

/* Create the checkbox/indicator (hidden when not checked) */
.checkbox:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkbox when checked */
.custom-checkbox-container input:checked ~ .checkbox:after {
  display: block;
}

/* Style the checkbox/indicator */
.custom-checkbox-container .checkbox:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
